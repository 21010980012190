import React, { useEffect, useState } from 'react';
import { T, O, M, A } from 'TOMA';
import s from './ProductList.module.scss';
import useApiContext from 'hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import useRepo from 'hooks/useRepo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreateProductForm from './CreateProductForm.page';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TechnicalForm from './TechnicalForm.page';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';

interface Product {
  id: number;
  name: string;
  type_produit: string;
  millesime: string;
}

interface ProductItemProps {
  item: {
    id: number;
    type_produit: string;
    name: string;
    products: Product[];
  };
}

function ProductList(): JSX.Element {
  const {
    userRepository,
    exhibitorRepository,
    mediaRepository,
    productRepository,
    codificationRepository,
  } = useApiContext();

  const user = userRepository.getUser();
  const [search, setSearch] = useState('');
  const [reload, setReload] = useState(0);
  const [showNewProductForm, setShowNewProductForm] = useState(false);
  const [productList, setProductList] = useState<Product[] | null>(null);
  const { exhibitorId } = useParams();
  const { t } = useTranslation();
  // Fetch products and update the state
  const {
    response: { products } = { products: null },
    loading: loadingProduct,
    error: errorProduct,
  } = useRepo(
    async () => await productRepository.getProducts(search, exhibitorId),
    [search, reload]
  );

  useEffect(() => {
    // @ts-expect-error
    setProductList(products);
  }, [products]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleDeleteProduct = async (id: number) => {
    const resp = await productRepository.deleteProduct(id);
    if (resp.status) {
      // Remove the deleted product from the state
      setReload(reload + 1);
      toast(resp.message, { type: 'success' });
    } else {
      toast(resp.message, { type: 'error' });
    }
  };

  return (
    <div>
      <div>
        <T.Container py="50">
          <M.Loading loading={loadingProduct} error={errorProduct} fullWiewport>
            <T.Block pb="50" mw="medium" m="auto" p="20" borderRadius="7">
              <A.Title
                as="h1"
                fontSize="48"
                mb="30"
                fw="black"
                textAlign="center"
              >
                {t('home:product.catalog_of_your_product')}
              </A.Title>
              <T.Flex p="30" pb="10" gap={2} halign="center">
                <T.Flex
                  className={s.ProductListSearchInputFlex}
                  valign="center"
                  gap="2"
                  direction="column"
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder={t('home:product.search')}
                    onChange={(e) => setSearch(e.target.value)}
                    name="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: '#D4D4D4',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D4D4D4',
                        },
                      },
                      width: '400px',
                    }}
                  />
                </T.Flex>
                <A.Button
                  onClick={() => setShowNewProductForm(true)}
                  bg="success"
                  className={s.ProductSearchButton}
                >
                  {t('home:product.new_product')}
                </A.Button>
              </T.Flex>
              <T.Flex mt="10" mb="40" direction="column">
                {productList?.map((item, index) => (
                  <ProductItem
                    key={index}
                    // @ts-expect-error
                    item={item}
                    onDelete={handleDeleteProduct}
                  />
                ))}
              </T.Flex>
            </T.Block>
            <CreateProductForm
              showNewProductForm={showNewProductForm}
              setShowNewProductForm={setShowNewProductForm}
            />
          </M.Loading>
        </T.Container>
      </div>
      <ToastContainer stacked />
    </div>
  );
}

function ProductItem({
  item,
  onDelete,
}: ProductItemProps & { onDelete: (id: number) => void }): JSX.Element {
  const [openItem, setOpenItem] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleDelete = () => {
    onDelete(item.id);
  };

  return (
    <T.Flex className={s.productMainSection} direction="column">
      <div
        className={s.productTitleSection}
        onClick={() =>
          setOpenItem(
            item?.type_produit === 'type_produit:WINE' ? !openItem : false
          )
        }
      >
        <span className={s.productTitle}>
          {openItem ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          {item?.name}
        </span>
        {item?.type_produit !== 'type_produit:WINE' && (
          <button
            onClick={() => navigate(`${pathname}/${item?.id}`)}
            className={s.productLink}
          >
            <img src="/icons/edit.svg" />
            {t('home:product.edit')}
          </button>
        )}
      </div>
      {openItem && (
        <>
          <div className={s.productContentSection_open}>
            <div className={s.productContentSection_millesime}>
              {item.products.map((row, index) => (
                <div key={index} className={s.millesimeRow}>
                  <div className={s.millesimeYear}>
                    {/* <button */}
                    {/*  onClick={() => onDelete(row.id)} */}
                    {/*  className={s.productLink} */}
                    {/* > */}
                    {/*  <div className={s.productDelete}> */}
                    {/*    <img src="/icons/delete.svg" /> */}
                    {/*  </div> */}
                    {/* </button> */}
                    <a
                      onClick={() => navigate(`${pathname}/${row.id}`)}
                      className={s.productTitleLink}
                    >
                      {row.millesime?.replace('millesime:', '')}
                    </a>
                  </div>
                  <Box sx={{ display: 'flex' }}>
                    <button
                      onClick={() => navigate(`${pathname}/${row.id}`)}
                      className={s.productLink}
                    >
                      <img src="/icons/edit.svg" />
                      {t('home:product.edit')}
                    </button>
                  </Box>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </T.Flex>
  );
}

export default ProductList;
