import Client from '../Client.provider';
import {
  iProductForm,
  iRest,
  iProductsGroupResponseRest,
  iProductByMillesimeForm,
} from './Product.type';

export interface iProductRepository {
  getProduct: (productId?: string) => Promise<iRest>;
  newProduct: (
    form: iProductForm,
    exhibitorId: string | number | undefined
  ) => Promise<iRest>;
  updateProduct: (
    form: iProductForm,
    exhibitorId: string | number | undefined,
    productId: string | number | undefined
  ) => Promise<iRest>;
  downloadPdf: (productId: string | number | undefined) => Promise<Blob>;
  copyProduct: (
    form: any,
    exhibitorId: string | number | undefined
  ) => Promise<any>;
  getProducts: (
    search?: string,
    exhibitorId?: any
  ) => Promise<iProductsGroupResponseRest>;
  createProduct: (
    form: iProductByMillesimeForm,
    exhibitorId: string | number | undefined
  ) => Promise<iRest>;
  deleteProduct: (productId: string | number) => Promise<any>;
}

export class ProductRepository extends Client implements iProductRepository {
  public async getProduct(productId?: string): Promise<iRest> {
    if (!productId) return { product: null };
    return await this.restricted()
      .get<iRest>(`/user/product/${productId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async newProduct(
    form: iProductForm,
    exhibitorId: string | number = ''
  ): Promise<iRest> {
    return await this.restricted()
      .post<iRest>(`/user/exhibitor/${exhibitorId}/product`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateProduct(
    form: iProductForm,
    exhibitorId: string | number = '',
    productId: string | number = ''
  ): Promise<iRest> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitor/${exhibitorId}/product/${productId}`, form)
      .then((e) => e.data)
      .catch((error) => {
        console.log(error);
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async downloadPdf(productId: string | number = ''): Promise<Blob> {
    return await this.restricted()
      .get<Blob>(`/user/product/${productId}/generate-pdf`, {
        responseType: 'blob', // Important for handling file downloads
      })
      .then((e) => e.data)
      .catch((error) => {
        console.log(error);
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async copyProduct(
    form: any,
    exhibitorId: string | number = ''
  ): Promise<any> {
    return await this.restricted()
      .post<iRest>(`/user/exhibitor/${exhibitorId}/product`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getProducts(
    search?: string,
    exhibitorId?: any
  ): Promise<iProductsGroupResponseRest> {
    let link = '';

    if (exhibitorId) {
      link = `/user/products?exhibitorId=${exhibitorId}`;
    } else {
      link = `/user/products`;
    }
    return await this.restricted()
      .get<iProductsGroupResponseRest>(link, {
        params: { search },
      })
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async createProduct(
    form: iProductByMillesimeForm,
    exhibitorId: string | number = ''
  ): Promise<iRest> {
    return await this.restricted()
      .post<iRest>(`/user/products`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async deleteProduct(productId: string | number = ''): Promise<any> {
    return await this.restricted()
      .delete<iRest>(`/user/product/${productId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
