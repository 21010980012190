export default {
  forgotPassword: {
    title: 'Forgot Password',
    email: 'Email',
    validate: 'Validate',
    success: 'You will receive an email to change your password.',
    new_password: 'New password',
    validate_password: 'Validate the password',
    password_must_match: 'Passwords must match',
    password_changed: 'VotreYour password has been changed.',
    send: 'Send',
    validate_password_rules:
      '(minimum 8 alphanumeric characters, minimum one uppercase)',
  },
};
