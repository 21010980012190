export default {
  users: {
    list_of_users: 'List of users',
    role: 'Role',
    number_of_authorized_exhibitors: 'Number of authorized exhibitors',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'Email',
    create_a_new_moderator: 'Create a new moderator',
    exhibitors: 'Exhibitors',
    collective: 'Collective',
    events: 'Events',
    password: 'Password',
    phone: 'Phone',
    last_login: 'Last login',
  },
};
