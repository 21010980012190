import React, { useEffect, useState, useRef } from 'react';
import {
  generatePath,
  Link,
  Outlet,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom';
import useApiContext from 'hooks/useApiContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import useRepo from 'hooks/useRepo';
import useModalContext from 'hooks/useModalContext';
import { A, M, T } from 'TOMA';
import { Switch } from 'components/Switch';
import { InputPhone, InputText, InputCheckbox } from 'form';
import { eRoles } from 'interfaces/Roles';
import s from './EventRegistration.module.scss';
import Exhibitor from 'pages/Exhibitor';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import EventProductCard from './EventProductCard.page';
import EventShowroomProductCard from './EventShowroomProductCard.page';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { InputAdornment, TextField } from '@mui/material';
import { DateRange } from '@mui/icons-material';
import CreateProductForm from '../ProductList/CreateProductForm.page';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ExhibitorForEvent from '../Exhibitor/ExhibitorForEvent';
import routes from '../../router/routes';
import { toast } from 'react-toastify';

export interface iForm {
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;
  contract_position: string;
  visibility: number;
  individual?: number;
  company?: number;
  image1: string;
  image2: string;
  answer_1: string;
  answer_2: string;
  answer_3: string;
  collectif: string;
}

function EventRegistration(): JSX.Element {
  const childFormRef = useRef<{ submitForm: () => void }>(null);
  const handleParentSubmit = (e: any): any => {
    e.preventDefault();
    console.log('Parent form submitted');

    if (childFormRef.current) {
      console.log(1);
      childFormRef.current.submitForm();
    }
  };
  const {
    userRepository,
    codificationRepository,
    exhibitorEventRepository,
    productRepository,
  } = useApiContext();
  const { exhibitorEventId, exhibitorId, eventId } = useParams();
  const [fireFetchProduct, setFireFetchProduct] = useState(0);
  const [fireFetchShowroomProduct, setFireFetchShowroomProduct] = useState(0);
  const { t } = useTranslation();
  const user = userRepository.getUser();
  const { response, loading, error } = useRepo(async () => {
    if (!exhibitorId) {
      return await exhibitorEventRepository.getExhibitorEvent(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(exhibitorEventId!)
      );
    } else {
      return await exhibitorEventRepository.getExhibitorEventAdmin(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(exhibitorId)
      );
    }
  });

  const { response: products } = useRepo(async () => {
    const id = exhibitorEventId
      ? parseInt(exhibitorEventId)
      : response?.exhibitorEvent?.id;
    if (id) {
      return await exhibitorEventRepository.getExhibitorEventProduct(id);
    }
    return null;
  }, [fireFetchProduct, response]);

  const { response: showroom_products } = useRepo(async () => {
    const id = exhibitorEventId
      ? parseInt(exhibitorEventId)
      : response?.exhibitorEvent?.id;
    if (id) {
      return await exhibitorEventRepository.getExhibitorEventShowroomProduct(
        id
      );
    }
    return null;
  }, [fireFetchShowroomProduct, response]);

  const { response: collectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );
  const {
    response: productLists,
    reload: reloadProducts,
    loading: loadingProducts,
  } = useRepo(async () => await productRepository.getProducts('', exhibitorId));
  const [isSend, setIsSendSent] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState<string>();
  const [answers, setAnswers] = useState<{ [key: string]: string | number }>(
    {}
  );
  const generateQuestionBlock = (): any => {
    return response?.questions.map((item: any, index: number) => {
      const i = index + 1; // Starting index from 1 if needed
      const fieldName = `answer_${i}`;
      console.log(item);
      if (i <= 3) {
        return (
          <React.Fragment key={fieldName}>
            {' '}
            <Controller
              // @ts-expect-error
              name={`answer_${index + 1}`}
              control={control}
              render={({ field }) => (
                <A.InputSelect
                  {...field}
                  name={fieldName}
                  required={!!item.required}
                  label={item.question}
                  disabled={response?.exhibitorEvent.locked}
                  options={item.answers.map((answer: any, index: number) => ({
                    label: answer,
                    value: `answer_${index + 1}`,
                  }))}
                />
              )}
            />
          </React.Fragment>
        );
      }

      return null; // Ensures a value is returned
    });
  };
  const schema = yup.object({
    first_name: yup.string().max(255).required(t('event:required')).nullable(),
    last_name: yup.string().max(255).required(t('event:required')).nullable(),
    telephone: yup.string().max(255).required(t('event:required')).nullable(),
    email: yup
      .string()
      .max(255)
      .email('Please enter a valid email')
      .required(t('event:required'))
      .nullable(),
    // @ts-expect-error
    answer_1: response?.questions[0]?.required
      ? yup.string().required(t('event:required'))
      : null,
    // @ts-expect-error
    answer_2: response?.questions[1]?.required
      ? yup.string().required(t('event:required'))
      : null,
    // @ts-expect-error
    answer_3: response?.questions[2]?.required
      ? yup.string().required(t('event:required'))
      : null,
  });

  const form = useForm<iForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, watch, control, reset, setValue, getValues } = form;
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleCheckboxChange = (value: any): any => {
    if (value === 'individual') {
      setValue('collectif', '');
    }
    setSelectedOption(value);
  };
  useEffect(() => {
    if (response?.exhibitorEvent) {
      reset({
        ...response?.exhibitorEvent,
        ...products,
      });
      if (response?.exhibitorEvent?.collectif) {
        setSelectedOption('company');
      } else {
        setSelectedOption('individual');
      }
    }
  }, [response, products]);
  const navigate = useNavigate();
  const handleRedirect = (): any => {
    const path = generatePath(`${routes.event.path}`, { eventId });
    navigate(path, {
      replace: true,
      state: { reloadKey: Date.now() },
    });
    setReload((e) => e + 1);
  };
  function onSubmit(data: iForm, is_sent: boolean = false): void {
    setLoadingForm(true);
    console.log('data', data);
    if (!event) {
      setErrorForm('Erreur inconnue...');
      setLoadingForm(false);
      return;
    }
    const payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      visibility: data.visibility,
      telephone: data.telephone,
      email: data.email,
      contract_position: data.contract_position,
      is_send: is_sent,
      answer_1: data.answer_1,
      answer_2: data.answer_2,
      answer_3: data.answer_3,
      collectif: data.collectif,
    };
    console.log('data', payload);

    Promise.all([
      exhibitorEventRepository
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // @ts-expect-error
        .mergeExhibitorEvent(parseInt(response?.exhibitorEvent?.id), payload)
        .finally(() => setLoadingForm(false)),
    ])
      .then(() => {
        setSuccessForm(true);
        setErrorForm('');
      })
      .catch((err: Error) => {
        toast(err.message, { type: 'error' });
        setErrorForm(err.message);
      })
      .finally(() => {
        setLoadingForm(false);
        if (is_sent) {
          toast(t('event:exhibitror_successfilly_updated'), {
            type: 'success',
          });
        } else {
          toast('Form submitted successfully!', { type: 'success' });
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        user?.role === 'ADMIN' ? handleRedirect() : '';
      });
  }

  const mediaList: Array<
    'media_1' | 'media_2' | 'media_3' | 'media_4' | 'media_5'
  > = ['media_1', 'media_2', 'media_3', 'media_4', 'media_5'];

  const [selectedYear, setSelectedYear] = React.useState('');

  const handleChange = (event: SelectChangeEvent): void => {
    const selectedValue = event.target.value;
    setSelectedYear(selectedValue);
    if (selectedValue) {
      handleAddProduct(event);
    }
  };

  const [selectedProduct, setSelectedProduct] = useState<any>('');
  const [selectedProductIndex, setSelectedProductIndex] = useState<any>('');

  const handleSelectedProduct = (event: SelectChangeEvent): void => {
    const product = productLists?.products?.find(
      (item, index) => index === Number(event.target.value)
    );
    setSelectedProduct(product ?? null);
    setSelectedProductIndex(event.target.value);
    setSelectedYear('');
  };

  const handleAddProduct = async (event: any): Promise<boolean> => {
    if (selectedProduct.products[0].type_produit !== 'type_produit:WINE') {
      await exhibitorEventRepository.addExhibitorProductEvent(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(String(response?.exhibitorEvent.id) ?? exhibitorEventId!),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(selectedProduct.products[0].id)
      );
      setSelectedYear('');
      setSelectedProduct(null);
      setSelectedProductIndex('');
      setFireFetchProduct(fireFetchProduct + 1);
    } else if (selectedYear !== '') {
      await exhibitorEventRepository.addExhibitorProductEvent(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(String(response?.exhibitorEvent.id) ?? exhibitorEventId!),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(selectedYear)
      );
      setSelectedYear('');
      setSelectedProduct(null);
      setSelectedProductIndex('');
      setFireFetchProduct(fireFetchProduct + 1);
    } else {
      setShowAlert(true);
      setAlertMessage(
        'Please select year for product and then click on add button.'
      );
    }
    return false;
  };

  const [selectedShowroomProduct, setSelectedShoroomProduct] =
    useState<any>('');
  const [selectedShowroomProductIndex, setSelectedShowroomProductIndex] =
    useState<any>('');

  const [selectedShowroomYear, setSelectedShowroomYear] = React.useState('');

  const handleShowroomChange = (event: SelectChangeEvent): void => {
    setSelectedShowroomYear(event.target.value);
  };

  const handleSelectedShowroomProduct = (event: SelectChangeEvent): void => {
    const product = productLists?.products?.find(
      (item, index) => index === Number(event.target.value)
    );
    setSelectedShoroomProduct(product ?? null);
    setSelectedShowroomProductIndex(event.target.value);
    console.log(1);
    handleAddShowroomProduct(event);
  };

  const handleAddShowroomProduct = async (event: any): Promise<boolean> => {
    if (
      selectedShowroomProduct.products[0].type_produit !== 'type_produit:WINE'
    ) {
      await exhibitorEventRepository.addExhibitorShowroomProductEvent(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(exhibitorEventId!),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(selectedProduct.products[0].id)
      );
      setSelectedShowroomYear('');
      setSelectedShoroomProduct(null);
      setSelectedShowroomProductIndex('');
      setFireFetchShowroomProduct(fireFetchShowroomProduct + 1);
    } else if (selectedShowroomYear !== '') {
      await exhibitorEventRepository.addExhibitorShowroomProductEvent(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(exhibitorEventId!),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(selectedShowroomYear)
      );
      setSelectedShowroomYear('');
      setSelectedShoroomProduct(null);
      setSelectedShowroomProductIndex('');
      setFireFetchShowroomProduct(fireFetchShowroomProduct + 1);
    } else {
      setShowAlert(true);
      setAlertMessage(
        'Please select year for product and then click on add button.'
      );
    }
    return false;
  };

  const [showNewProductForm, setShowNewProductForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [hideInformationAboutCompany, setHideInformationAboutCompany] =
    useState(false);
  const handleClose = (): void => {
    setShowNewProductForm(false);
  };
  const [reload, setReload] = useState<number>(0);
  const { pathname } = useLocation();
  const hideAlert = (): void => {
    setShowAlert(false);
    setAlertMessage('');
  };
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  useEffect(() => {
    console.log('exhibitorEventDetail2', response);
    console.log('products2', products);
  }, [response, products]);
  const [productListState, setProductlistState] = useState(productLists);
  const newProductId = queryParams.get('newProductId');
  useEffect(() => {
    // Define a function to reload products and handle the state update
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchProducts = async () => {
      // If we're already loading, don't reload again
      if (loadingProducts) return;

      // Trigger reload of products
      await reloadProducts();
    };

    // Only call fetchProducts when newProductId changes
    if (newProductId && !loadingProducts) {
      fetchProducts();
    }
  }, [newProductId]); // Trigger only when newProductId changes

  useEffect(() => {
    // Once loading is done, update the state
    if (!loadingProducts) {
      const product = productLists?.products?.find(
        (item) => item.id === Number(newProductId)
      );
      const productIndex = productLists?.products?.findIndex(
        (item) => item.id === Number(newProductId)
      );

      console.log(product);
      setSelectedProduct(product ?? null);
      setSelectedProductIndex(productIndex);
      setSelectedYear('');
    }
  }, [loadingProducts, productLists, newProductId]);
  console.log(products, 'productsproducts');
  return (
    <div>
      <div className={pathname.includes('product') ? s.blur : undefined}>
        <T.Container pb="50" pt="20">
          <M.Loading loading={loading} error={error} fullWiewport>
            <T.Block pb="50" mw="large" m="auto">
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showAlert}
                autoHideDuration={6000}
                onClose={hideAlert}
              >
                <Alert
                  onClose={hideAlert}
                  severity="error"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {alertMessage}
                </Alert>
              </Snackbar>
              <T.Block
                pb="50"
                mw="medium"
                m="auto"
                // bg="grey-05"
                p="20"
                borderRadius="7"
              >
                <T.Block>
                  <T.BlockResponsive
                    // bg="grey-light"
                    pb="20"
                    borderRadius="10"
                  >
                    <A.Title
                      as="h1"
                      fontSize="48"
                      fw="black"
                      className={s.eventInfoTitle}
                    >
                      {t('event:registration')}
                    </A.Title>
                  </T.BlockResponsive>
                  <T.Block
                    style={{
                      padding: '20px',
                      borderRadius: '10px',
                      backgroundColor: '#F2F2F2',
                    }}
                  >
                    <T.BlockResponsive>
                      <A.Title
                        className={s.eventInfoTitle}
                        as="h2"
                        fontSize="32"
                      >
                        {t('event:event_information')}
                      </A.Title>
                      <A.Line mb="20" mt="10" />
                      <T.Flex direction="column" gap={2}>
                        <TextField
                          sx={{
                            backgroundColor: '#F2F2F2',
                          }}
                          value={response?.event?.name}
                          label={t('event:name_of_the_event')}
                          variant="outlined"
                          disabled
                        />
                        <TextField
                          sx={{
                            backgroundColor: '#F2F2F2',
                          }}
                          value={
                            response?.event?.date
                              ? new Date(
                                  response.event.date
                                ).toLocaleDateString('en-GB')
                              : ''
                          }
                          label={t('event:date_of_event')}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DateRange />
                              </InputAdornment>
                            ),
                          }}
                          disabled
                        />
                        <TextField
                          sx={{
                            backgroundColor: '#F2F2F2',
                          }}
                          value={response?.event?.city}
                          label={t('event:city_of_event')}
                          variant="outlined"
                          disabled
                        />
                        <TextField
                          sx={{
                            backgroundColor: '#F2F2F2',
                          }}
                          value={response?.event?.country}
                          label={t('event:country_of_event')}
                          variant="outlined"
                          disabled
                        />
                        <TextField
                          sx={{
                            backgroundColor: '#F2F2F2',
                          }}
                          value={
                            response?.event?.deadline_date
                              ? new Date(
                                  response.event.deadline_date
                                ).toLocaleDateString('en-GB')
                              : ''
                          }
                          label={t('event:registration_closing_date')}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DateRange />
                              </InputAdornment>
                            ),
                          }}
                          disabled
                        />
                      </T.Flex>
                    </T.BlockResponsive>
                    {response?.event?.show_samples_information ? (
                      <T.BlockResponsive>
                        <A.Title
                          className={s.eventInfoTitle}
                          as="h2"
                          fontSize="32"
                          mt="20"
                        >
                          {t('event:sample_informations')}
                        </A.Title>
                        <A.Line mb="20" mt="10" />
                        <T.Flex direction="column" gap={2}>
                          <TextField
                            sx={{
                              backgroundColor: '#F2F2F2',
                            }}
                            value={response?.event?.number_of_samples}
                            label={t('event:numbers_of_sample_send')}
                            variant="outlined"
                            disabled
                          />
                          <TextField
                            sx={{
                              backgroundColor: '#F2F2F2',
                            }}
                            value={response?.event?.address}
                            label={t('event:address_to_send_semple')}
                            variant="outlined"
                            disabled
                          />
                          <TextField
                            sx={{
                              backgroundColor: '#F2F2F2',
                            }}
                            value={response?.event?.limit_date}
                            label={t('event:limit_date_to_receive_sample')}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <DateRange />
                                </InputAdornment>
                              ),
                            }}
                            disabled
                          />
                        </T.Flex>
                      </T.BlockResponsive>
                    ) : (
                      ''
                    )}
                  </T.Block>
                  <A.Title mt="50" as="h1" fontSize="32" fw="bold">
                    <T.Flex>
                      <T.Block m="auto">
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setHideInformationAboutCompany(
                              (prevState) => !prevState
                            );
                          }}
                        >
                          {t('event:information_de_la_societe')}
                        </a>
                        {hideInformationAboutCompany ? (
                          <ArrowCircleRightOutlinedIcon
                            fontSize="medium"
                            style={{ cursor: 'pointer', fontSize: 28 }}
                            onClick={() => {
                              setHideInformationAboutCompany(() => false);
                            }}
                          />
                        ) : (
                          <ArrowCircleDownIcon
                            fontSize="medium"
                            style={{ cursor: 'pointer', fontSize: 28 }}
                            onClick={() => {
                              setHideInformationAboutCompany(() => true);
                            }}
                          />
                        )}
                      </T.Block>
                    </T.Flex>
                  </A.Title>
                  <A.Line mb="20" />
                  {!hideInformationAboutCompany ? (
                    <ExhibitorForEvent ref={childFormRef} />
                  ) : (
                    ''
                  )}
                  <form noValidate>
                    <A.Title as="h2" fontSize="32" fw="bold">
                      {t('exhibitors:my_company_is')}
                    </A.Title>
                    <A.Line mb="20" />
                    <T.BlockResponsive>
                      <T.Flex halign="space-between">
                        <A.Title as="h6" fontSize="18" fw="normal">
                          {t('exhibitors:individual')}
                        </A.Title>
                        <T.Block mt="5">
                          <A.InputCheckbox
                            label=""
                            // @ts-expect-error
                            value="individual"
                            checked={selectedOption === 'individual'} // Перевіряємо чи це вибраний елемент
                            onChange={() => handleCheckboxChange('individual')}
                            disabled={response?.exhibitorEvent.locked}
                          />
                        </T.Block>
                      </T.Flex>

                      <T.Flex mt="10" halign="space-between">
                        <A.Title as="h6" fontSize="18" fw="normal">
                          Sur un stand collectif régional ou interprofessionnel
                        </A.Title>
                        <T.Block mt="5">
                          <A.InputCheckbox
                            label=""
                            // @ts-expect-error
                            value="company"
                            checked={selectedOption === 'company'} // Перевіряємо чи це вибраний елемент
                            onChange={() => handleCheckboxChange('company')}
                            disabled={response?.exhibitorEvent.locked}
                          />
                        </T.Block>
                      </T.Flex>
                      {selectedOption === 'company' ? (
                        <T.Flex halign="center">
                          <Controller
                            name={`collectif`}
                            control={control}
                            render={({ field }) => (
                              <A.InputSelect
                                {...field}
                                placeholder="Selectionnez..."
                                name="collectif"
                                disabled={response?.exhibitorEvent.locked}
                                options={collectif?.map((item) => {
                                  return {
                                    value: item.code,
                                    label: item.label,
                                  };
                                })}
                              />
                            )}
                          />
                        </T.Flex>
                      ) : (
                        ''
                      )}
                    </T.BlockResponsive>
                    <A.Title as="h2" fontSize="32" mt="50" fw="bold">
                      {t('event:contact_for_this_event')}
                    </A.Title>
                    <A.Line mb="20" mt="20" />
                    <T.BlockResponsive>
                      <InputText
                        label={t('event:sur_name')}
                        name="first_name"
                        type="text"
                        disabled={response?.exhibitorEvent.locked}
                        control={control}
                      />
                      <InputText
                        label={t('event:last_name')}
                        name="last_name"
                        type="text"
                        disabled={response?.exhibitorEvent.locked}
                        control={control}
                      />

                      <InputPhone
                        label={t('event:mobile_phone')}
                        name="telephone"
                        control={control}
                        disabled={response?.exhibitorEvent.locked}
                        placeholder="+33 6 23 45 67 88"
                      />

                      <InputText
                        label={t('event:email')}
                        name="email"
                        type="email"
                        inputMode="email"
                        disabled={response?.exhibitorEvent.locked}
                        control={control}
                      />

                      <InputText
                        label={t('event:contact_position')}
                        name="contract_position"
                        type="text"
                        control={control}
                        disabled={response?.exhibitorEvent.locked}
                        placeholder={t('event:contact_position')}
                      />
                    </T.BlockResponsive>

                    <A.Title as="h2" fontSize="24" mt="50" fw="black">
                      <T.Block>
                        {t('event:your_products')}* &nbsp;&nbsp;
                        <A.Text as="span" fontSize="14" fw="normal">
                          {t(
                            'event:possibility_of_presenting_up_to_10_references'
                          )}
                        </A.Text>
                      </T.Block>
                      <A.Line mb="20" mt="20" />
                      <T.BlockResponsive>
                        <T.Flex mt="20" mb="20" gap={5} direction="column">
                          {products?.map((product, index) => (
                            <EventProductCard
                              key={index}
                              product={product}
                              exhibitorEventId={exhibitorEventId}
                              fireFetchProduct={fireFetchProduct}
                              setFireFetchProduct={setFireFetchProduct}
                              event={response?.event}
                              locked={response?.exhibitorEvent.locked}
                              exhibitorEventAdminId={
                                response?.exhibitorEvent.id
                              }
                              role={user?.role}
                            />
                          ))}
                        </T.Flex>
                        <>
                          <T.Flex mt="20" mb="10" gap={5}>
                            <FormControl
                              sx={{
                                width: '100%',
                              }}
                            >
                              <InputLabel id="select-product-list">
                                {t('event:select_product')}
                              </InputLabel>
                              <Select
                                labelId="select-product-list"
                                id="select-product"
                                disabled={response?.exhibitorEvent.locked}
                                value={selectedProductIndex}
                                label={t('event:select_product')}
                                onChange={handleSelectedProduct}
                              >
                                {productLists?.products?.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={index}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            {selectedProduct &&
                              selectedProduct.products[0].type_produit ===
                                'type_produit:WINE' && (
                                <FormControl
                                  sx={{
                                    width: '200px',
                                  }}
                                >
                                  <InputLabel id="simple-select-label-millesime">
                                    Millesime
                                  </InputLabel>
                                  <Select
                                    labelId="simple-select-label-millesime"
                                    id="simple-select-millesime"
                                    value={selectedYear}
                                    label="Millesime"
                                    onChange={handleChange}
                                  >
                                    {selectedProduct.products?.map(
                                      (item: any, index: any) => (
                                        <MenuItem key={index} value={item.id}>
                                          {item.millesime?.replace(
                                            'millesime:',
                                            ''
                                          )}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                          </T.Flex>
                          <T.Flex halign="center">
                            <IconButton
                              onClick={handleAddProduct}
                              aria-label="delete"
                              size="large"
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'transparent', // Removes background on hover
                                },
                                '&:focus': {
                                  backgroundColor: 'transparent', // Removes focus (click) background
                                },
                                '&.Mui-focusVisible': {
                                  backgroundColor: 'transparent', // Removes focus background on click
                                },
                                '& .MuiTouchRipple-root': {
                                  display: 'none', // Removes the ripple effect
                                },
                              }}
                            >
                              <img src="/icons/add.svg" />
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: '16px',
                                  marginLeft: '14px',
                                }}
                              >
                                {t('event:add_product_from_catalog')}
                              </Typography>
                            </IconButton>
                          </T.Flex>
                        </>
                      </T.BlockResponsive>
                    </A.Title>
                    {user?.role === eRoles.EXHIBITOR ? (
                      <T.Flex
                        mt="20"
                        mb="20"
                        halign={'center'}
                        valign={'center'}
                      >
                        <A.Button
                          onClick={() => setShowNewProductForm(true)}
                          disabled={response?.exhibitorEvent.locked}
                          className={s.Button_product_add}
                        >
                          <A.Icon
                            icon="plus"
                            color="white"
                            fontSize="14"
                            mr="1"
                          />
                          {t('event:add_a_new_product')}
                        </A.Button>
                      </T.Flex>
                    ) : null}

                    <A.Line mb="20" mt="20" />
                    {generateQuestionBlock() ?? (
                      <>
                        <A.Title as="h2" fontSize="32" mt="50" fw="bold">
                          Other questions
                        </A.Title>
                        <A.Line mb="20" mt="20" />
                        <T.Block>{generateQuestionBlock()}</T.Block>
                      </>
                    )}
                    {Boolean(response?.event?.showroom) && (
                      <A.Title as="h2" fontSize="24" mt="50" fw="black">
                        <T.Flex gap={1}>
                          <div>Showroom &nbsp;&nbsp;</div>
                          <T.Flex gap={1} valign="center">
                            <Switch
                              name="showroom"
                              key={1}
                              defaultChecked={
                                !!response?.exhibitorEvent?.showroom
                              }
                              onChange={async (e: boolean) => {
                                const data = {
                                  showroom: e,
                                };
                                await exhibitorEventRepository.mergeExhibitorEvent(
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  parseInt(exhibitorEventId!),
                                  data
                                );
                                return true;
                              }}
                            />
                          </T.Flex>
                        </T.Flex>
                        <A.Text as="span" fontSize="14" fw="normal">
                          {t(
                            'event:possibility_of_presenting_up_to_10_references'
                          )}
                        </A.Text>
                        <A.Line mb="20" mt="20" />
                        <T.BlockResponsive>
                          <T.Flex mt="20" mb="20" gap={5} direction="column">
                            {showroom_products?.map((product, index) => (
                              <EventShowroomProductCard
                                key={index}
                                product={product}
                                exhibitorEventId={
                                  user?.role === 'EXHIBITOR'
                                    ? exhibitorId
                                    : exhibitorEventId
                                }
                                fireFetchProduct={fireFetchShowroomProduct}
                                setFireFetchProduct={
                                  setFireFetchShowroomProduct
                                }
                                event={response?.event}
                              />
                            ))}
                          </T.Flex>
                          {user?.role === eRoles.EXHIBITOR ? (
                            <>
                              <T.Flex mt="20" mb="10" gap={5}>
                                <FormControl
                                  sx={{
                                    width: '100%',
                                  }}
                                >
                                  <InputLabel id="select-product-list">
                                    {t('event:select_product')}
                                  </InputLabel>
                                  <Select
                                    labelId="select-product-list"
                                    id="select-product"
                                    value={selectedShowroomProductIndex}
                                    disabled={response?.exhibitorEvent.locked}
                                    label={t('event:select_product')}
                                    onChange={handleSelectedShowroomProduct}
                                  >
                                    {productLists?.products?.map(
                                      (item, index) => {
                                        return (
                                          <MenuItem key={index} value={index}>
                                            {item.name}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                                {selectedShowroomProduct &&
                                  selectedShowroomProduct.products[0]
                                    .type_produit === 'type_produit:WINE' && (
                                    <FormControl
                                      sx={{
                                        width: '200px',
                                      }}
                                    >
                                      <InputLabel id="simple-select-label-millesime">
                                        Millesime
                                      </InputLabel>
                                      <Select
                                        labelId="simple-select-label-millesime"
                                        id="simple-select-millesime"
                                        disabled={
                                          response?.exhibitorEvent.locked
                                        }
                                        value={selectedShowroomYear}
                                        label="Millesime"
                                        onChange={handleShowroomChange}
                                      >
                                        {selectedShowroomProduct.products?.map(
                                          (item: any, index: any) => (
                                            <MenuItem
                                              key={index}
                                              value={item.id}
                                            >
                                              {item.millesime?.replace(
                                                'millesime:',
                                                ''
                                              )}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  )}
                              </T.Flex>
                              <T.Flex halign="center" mb="20">
                                <IconButton
                                  onClick={handleAddShowroomProduct}
                                  size="large"
                                  disabled={response?.exhibitorEvent.locked}
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: 'transparent', // Removes background on hover
                                    },
                                    '&:focus': {
                                      backgroundColor: 'transparent', // Removes focus (click) background
                                    },
                                    '&.Mui-focusVisible': {
                                      backgroundColor: 'transparent', // Removes focus background on click
                                    },
                                    '& .MuiTouchRipple-root': {
                                      display: 'none', // Removes the ripple effect
                                    },
                                  }}
                                >
                                  <img src="/icons/add.svg" />
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: '16px',
                                      marginLeft: '14px',
                                    }}
                                  >
                                    {t('event:add_product_from_catalog')}
                                  </Typography>
                                </IconButton>
                              </T.Flex>
                            </>
                          ) : null}
                        </T.BlockResponsive>
                      </A.Title>
                    )}

                    {/* <A.Alert alert="success" side="🎉" mb="15"> */}
                    {/*  {successForm && 'Form submitted successfully !'} */}
                    {/* </A.Alert> */}
                    {/* <A.Alert alert="error" side="⚠️" mb="15"> */}
                    {/*  {errorForm && <A.InnerHTML html={errorForm} />} */}
                    {/* </A.Alert> */}
                    {user?.role === 'MODERATOR' ? (
                      ''
                    ) : (
                      <>
                        <T.Flex halign="center" gap={1} wrap>
                          {response?.exhibitorEvent.locked ? (
                            ''
                          ) : (
                            <>
                              <div>
                                <A.Button
                                  type="button"
                                  color="primary"
                                  onClick={(e) => {
                                    handleParentSubmit(e);
                                    handleSubmit(() => onSubmit(getValues()))();
                                  }}
                                  loading={loadingForm}
                                >
                                  {t('event:save')}*
                                </A.Button>
                              </div>
                              <div>
                                <A.Button
                                  color="black"
                                  type="button"
                                  onClick={(e) => {
                                    console.log(
                                      products?.length,
                                      'products?.length'
                                    );
                                    if (products?.length === 0) {
                                      toast(
                                        t('event:add_the_least_one_product'),
                                        {
                                          type: 'error',
                                        }
                                      );
                                    }
                                    // @ts-expect-error
                                    if (products?.length > 0) {
                                      setIsSendSent(true);
                                      handleParentSubmit(e);
                                      console.log(isSend, 'isSend');
                                      handleSubmit(() =>
                                        onSubmit(getValues(), true)
                                      )();
                                    }
                                  }}
                                  bg="grey-light"
                                  iconBefore="lock-fill"
                                  loading={loadingForm}
                                >
                                  {t('event:save_and_transmit')}**
                                </A.Button>
                              </div>
                            </>
                          )}
                        </T.Flex>
                        {response?.exhibitorEvent.locked ? (
                          ''
                        ) : (
                          <>
                            <A.Text fontSize="14" textAlign="center">
                              {t(
                                'event:possibility_to_reconnect_and_sequence_the_entry_of_information'
                              )}
                            </A.Text>
                            <A.Text fontSize="14" textAlign="center">
                              {t(
                                'event:your_entry_is_finalized_the_elements_can_no_longer_be_modified'
                              )}
                            </A.Text>
                          </>
                        )}
                      </>
                    )}
                  </form>
                </T.Block>
                <CreateProductForm
                  showNewProductForm={showNewProductForm}
                  setShowNewProductForm={setShowNewProductForm}
                />
              </T.Block>
            </T.Block>
          </M.Loading>
        </T.Container>
      </div>
      {pathname.includes('product') && (
        <>
          <div className={s.ExhibitorList__outlet}>
            <div>
              <Link
                className={s.background}
                to={
                  user?.role !== 'EXHIBITOR'
                    ? generatePath(
                        routes.event.path + '/exhibitor/:exhibitorId',
                        {
                          exhibitorId,
                          eventId,
                        }
                      )
                    : generatePath(routes.eventRegistration.path, {
                        exhibitorEventId,
                        eventId,
                      })
                }
                onClick={() => setReload((e) => e + 1)}
              />
            </div>
            <div className={s.ExhibitorList__outlet__content}>
              {user?.role === 'EXHIBITOR' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '20px',
                  }}
                >
                  <Link
                    to={generatePath(routes.eventRegistration.path, {
                      eventId,
                      exhibitorEventId:
                        user?.role === 'EXHIBITOR'
                          ? exhibitorEventId
                          : exhibitorId,
                    })}
                    onClick={() => setReload((e) => e + 1)}
                  >
                    <div>
                      <A.Icon icon="x-lg" fontSize="24" />
                    </div>
                  </Link>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '20px',
                  }}
                >
                  <Link
                    to={generatePath(
                      routes.event.path + '/exhibitor/:exhibitorId',
                      {
                        eventId,
                        exhibitorId:
                          // @ts-expect-error
                          user?.role === 'EXHIBITOR'
                            ? exhibitorEventId
                            : exhibitorId,
                      }
                    )}
                    onClick={() => setReload((e) => e + 1)}
                  >
                    <div>
                      <A.Icon icon="x-lg" fontSize="24" />
                    </div>
                  </Link>
                </div>
              )}
              <Outlet />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EventRegistration;
