export default {
  product: {
    title: 'Enregistrement produit',
    product: 'Produit',
    newProduct: 'Nouveau produit',
    addProduct: 'Ajouter un produit',

    form: {
      producer: 'Producteur',
      productName: 'Nom du produit',
      productDescription:
        'Description du produit en anglais (300 caractères maximum)',
      productVisual: 'Visuel du produit',
      productVisualInfo:
        'Votre visuel produit : merci de joindre une photo de la bouteille du produit présenté',
      productInfo: {
        title: 'Informations produit',
        productType: 'Type de produit',
        vineType: 'Type de vins',
        color: 'Couleur',
        region: 'Région',
        classification: 'Classification',
        appellation: 'Appellation AOC/AOP',
        denominationIgp: 'Dénomination IGP',
        denomination: 'Dénomination autre : VDF / autre vins / Spiritueux',
        typeCulture: 'Type de culture',
        millesime: 'Millésime',
        cepage: 'Cépages (plusieurs choix possibles)',
        format: 'Format',
        price: 'Fourchette de prix',
        alcool: 'Taux d’alcool',
        typeVendange: 'Type de Vendanges',
        allergene: 'Allergène(s)',
        arome: 'Arômes',
        technicalFile: 'Technical file',
        notes: 'Note de dégustation',
        new_milesime: 'Nouveau millésime',
      },
      success: 'Produit enregistré avec succès',
      save: 'Enregistrer',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      confirmRemoveProduct: 'Supprimer le produit ?',
      remove: 'Supprimer',
    },
  },
};
