import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApiContext from 'hooks/useApiContext';
import Form, { InputText } from 'form';
import { T, O, M, A } from 'TOMA';
import { useTranslation } from 'react-i18next';

export interface iFormForgotPassword {
  email: string;
}

function ForgotPassword(): JSX.Element {
  const { userRepository } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string | undefined>();
  const { t } = useTranslation();

  const schema = yup.object({
    email: yup
      .string()
      .email(t('common:form.error.email'))
      .required(t('common:form.error.required')),
  });

  function handleSubmit({ email }: iFormForgotPassword): void {
    console.log(email, 'email');
    setLoading(true);
    userRepository
      .forgotPassword(email)
      .then(() => {
        setSuccess(
          // t('forgotPassword:success')
          'Vous allez recevoir un email pour changer votre mot de passe.'
        );
      })
      .catch((err: Error) => {
        console.log(err, 'error');
        // @ts-expect-error
        setError(err.response.data.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <T.Container py="50">
      <T.Block pb="50">
        <A.Title as="h1" fontSize="48" textAlign="center" fw="black">
          {t('forgotPassword:title')}
        </A.Title>
      </T.Block>
      <T.Block bg="grey-10" borderRadius="5" mw="medium" mx="auto" p="80">
        {!success && (
          <Form<iFormForgotPassword>
            onSubmit={async (data) => handleSubmit(data)}
            resolver={yupResolver(schema)}
          >
            {({ control }) => (
              <div>
                <T.Block mb="40">
                  <InputText
                    label={t('forgotPassword:email')}
                    name="email"
                    type="text"
                    control={control}
                    required
                  />
                </T.Block>

                <A.Alert alert="error" side="⚠️" mb="30">
                  {error && <A.InnerHTML html={error} />}
                </A.Alert>

                <A.Button type="submit" loading={loading} center>
                  {t('forgotPassword:validate')}
                </A.Button>
              </div>
            )}
          </Form>
        )}

        {success && (
          <>
            <A.Text fontSize="48" textAlign="center" mb="30">
              🎉
            </A.Text>
            <A.Text fontSize="18" textAlign="center" fw="bold">
              {success}
            </A.Text>
          </>
        )}
      </T.Block>
    </T.Container>
  );
}

export default ForgotPassword;
