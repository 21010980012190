import React, { useState } from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import useApiContext from 'hooks/useApiContext';
import Form, { InputText } from 'form';
import { T, O, M, A } from 'TOMA';
import { useTranslation } from 'react-i18next';

export interface iFormUpdatePassword {
  password: string;
  passwordConfirmation: string;
}

function UpdatePassword(): JSX.Element {
  const { updatePasswordId } = useParams();
  const { userRepository } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string | undefined>();
  const { t } = useTranslation();

  const schema = yup.object({
    password: yup
      .string()
      .pipe(
        yup
          .string()
          .min(8, '<b>8 characters</b>')
          .minLowercase(1, '<b>one lowercase</b>')
          .minUppercase(1, '<b>one uppercase</b>')
          .minNumbers(1, '<b>one number</b>'),
        `The password must contain at least \${items}.`,
        'and'
      )
      .required('This field is required'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('This field is required'),
  });

  function handleSubmit({ password }: iFormUpdatePassword): void {
    setLoading(true);
    userRepository
      .updatePassword(password, updatePasswordId)
      .then(() => {
        setSuccess('Votre mot de passe a été changé.');
      })
      .catch((err: Error) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <T.Container py="50">
      <T.Block pb="50">
        <A.Title as="h1" fontSize="48" textAlign="center" fw="black">
          {t('forgotPassword:title')}
        </A.Title>
      </T.Block>
      <T.Block bg="grey-10" borderRadius="5" mw="medium" mx="auto" p="80">
        {!success && (
          <Form<iFormUpdatePassword>
            onSubmit={async (data) => handleSubmit(data)}
            resolver={yupResolver(schema)}
          >
            {({ control }) => (
              <div>
                <T.Block mb="40">
                  <InputText
                    label={
                      t('forgotPassword:new_password') +
                      ' ' +
                      t('forgotPassword:validate_password_rules')
                    }
                    name="password"
                    type="password"
                    control={control}
                    required
                  />
                  <InputText
                    label={t('forgotPassword:validate_password')}
                    name="passwordConfirmation"
                    type="password"
                    control={control}
                    required
                  />
                </T.Block>

                <A.Alert alert="error" side="⚠️" mb="30">
                  {error && <A.InnerHTML html={error} />}
                </A.Alert>

                <A.Button type="submit" loading={loading} center>
                  {t('forgotPassword:send')}
                </A.Button>
              </div>
            )}
          </Form>
        )}

        {success && (
          <>
            <A.Text fontSize="48" textAlign="center" mb="30">
              🎉
            </A.Text>
            <A.Text fontSize="18" textAlign="center" fw="bold">
              {success}
            </A.Text>
          </>
        )}
      </T.Block>
    </T.Container>
  );
}

export default UpdatePassword;
