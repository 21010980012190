import React, { useState, useMemo, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import useApiContext from 'hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  useLocation,
  Outlet,
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { format, isAfter } from 'date-fns';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import s from './ExhibitorList.module.scss';
import routes from 'router/routes';
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Switch } from 'components/Switch';
import { CustomLink } from 'components/CustomLink';
import { InputStand } from 'components/InputStand';
import { ExhibitorListHeader } from 'components/ExhibitorListHeader';
import Extraction from 'components/Extraction/Extraction';
import { eRoles } from '../../interfaces/Roles';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper<any>();

function ExhibitorList(): JSX.Element {
  const [csvError, setCsvError] = useState<string | undefined>(undefined);
  const [csvSuccess, setCsvSuccess] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const { setModalContent, setModalVisible } = useModalContext();
  const { pathname } = useLocation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const {
    userRepository,
    exhibitorRepository,
    exhibitorEventRepository,
    codificationRepository,
    preferenceRepository,
  } = useApiContext();
  const user = userRepository.getUser();
  const isModerator = user?.role === eRoles.MODERATOR;
  const isAdmin = user?.role === eRoles.ADMIN;
  const [filterCollectif, setFilterCollectif] = useState<any>();
  const [filterName, setFilterName] = useState<any>();
  const [reload, setReload] = useState<number>(0);
  const { eventId } = useParams();
  const { t } = useTranslation();
  const {
    response = [],
    loading,
    error,
  } = useRepo(async () => {
    if (!pathname.includes('events')) {
      return await exhibitorRepository.getExhibitorsWithoutEvent();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return await exhibitorRepository.getExhibitors(eventId!);
    }
  }, [reload]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const downloadCSV = async () => {
    try {
      toast('Start file generation', { type: 'info' });
      setIsLoading(true);
      const response = await exhibitorRepository.downloadCSV(eventId);
      setTimeout(() => {
        toast('Now you can download the file', { type: 'success' });
      }, 500);
      setCsvData(response.data.data);
      setIsDataReady(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error downloading the file:', error);
      setIsLoading(false);
    }
  };

  const { response: dateLimit } = useRepo(
    async () => await preferenceRepository.find('date_limit'),
    [reload]
  );

  const { response: codifCollectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );
  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor('status', {
        header: t('event:status'),
        cell: (info) => {
          const val = info.getValue();
          return (
            <div className={s.ExhibitorList__status} data-key={val}>
              {val}
            </div>
          );
        },
      }),
      columnHelper.accessor('number', {
        header: t('admin:number'),
      }),
      // columnHelper.accessor('active', {
      //   header: 'Activé',
      //   enableSorting: false,
      //   cell: ({ getValue }) => {
      //     const { value, userId, exhibId } = getValue();
      //     return (
      //       <Switch
      //         key={exhibId}
      //         defaultChecked={value}
      //         onChange={async (e) =>
      //           await userRepository.updateUserActive(e, userId)
      //         }
      //       />
      //     );
      //   },
      // }),
      columnHelper.accessor('locked', {
        header: t('admin:locked'),
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, exhibId } = getValue();
          return (
            <Switch
              key={exhibId}
              checked={value}
              onChange={async (status) =>
                await exhibitorRepository
                  .mergeUserExhibitor('locked', status, exhibId, eventId)
                  .finally(() => setReload((prev) => prev + 1))
              }
            />
          );
        },
      }),
      columnHelper.accessor('raisonSocial', {
        header: t('admin:company'),
        cell: (info) => {
          const val = info.getValue();
          return (
            <CustomLink to={`${pathname}/exhibitor/${val.exhibId}`}>
              <b>{val.value}</b>
            </CustomLink>
          );
        },
      }),
      columnHelper.accessor('nomCommercial', {
        header: t('exhibitors:nom_commercial'),
      }),
      columnHelper.accessor('collectif', {
        header: t('exhibitors:participation'),
        cell: (info) => (
          <div className={s.ExhibitorList__collectif}>
            {info.renderValue().length > 0 ? info.renderValue() : 'Individual'}
          </div>
        ),
      }),
      columnHelper.accessor('numeroHall', {
        header: t('exhibitors:numero_de_stand'),
        enableSorting: false,
        cell: (info) => {
          const { stand, hall, exhibId } = info.renderValue();
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <T.Flex key={exhibId} valign="center" gap="1">
                <InputStand
                  label="Stand"
                  defaultValue={stand}
                  disable={user?.role === 'MODERATOR'}
                  onChange={async (e) =>
                    await exhibitorRepository
                      .mergeUserExhibitor('stand', e, exhibId, eventId)
                      .finally(() => setReload((r) => r + 1))
                  }
                />
                <InputStand
                  label="Hall"
                  defaultValue={hall}
                  disable={user?.role === 'MODERATOR'}
                  onChange={async (e) =>
                    await exhibitorRepository
                      .mergeUserExhibitor('hall', e, exhibId, eventId)
                      .finally(() => setReload((r) => r + 1))
                  }
                />
              </T.Flex>
            </div>
          );
        },
      }),
      columnHelper.accessor('edit', {
        header: '',
        cell: (info) => {
          const location = useLocation();
          const pathname = location.pathname;
          const val = info.getValue();
          return pathname === '/vins-de-loire' ? (
            <CustomLink to={`${pathname}/exhibitor/${val.exhibitorId}`}>
              {t('exhibitors:edit')}
            </CustomLink>
          ) : (
            <Button
              variant="outlined"
              color="error"
              key={val.exhibId}
              type="button"
              className={s.ExhibitorList__remove}
              onClick={async () =>
                await exhibitorRepository
                  .removeInvite(val.exhibitorId, eventId)
                  .then(() => {
                    setModalVisible(false);
                    setReload((prev) => prev + 1);
                  })
              }
            >
              {t('exhibitors:retirer')}
            </Button>
          );
        },
      }),
    ];

    // Add the "showroom" column only if the user's role is not "MODERATOR"
    if (user?.role !== 'MODERATOR') {
      baseColumns.splice(
        3,
        0,
        columnHelper.accessor('visibility', {
          header: t('event:participer'),
          enableSorting: false,
          cell: ({ getValue }) => {
            const { value, exhibId } = getValue();
            return (
              <Switch
                key={exhibId}
                checked={value}
                onChange={async (e) =>
                  await exhibitorRepository.mergeUserExhibitor(
                    'visibility',
                    e,
                    exhibId,
                    eventId
                  )
                }
              />
            );
          },
        })
      );
      baseColumns.splice(
        3,
        0,
        columnHelper.accessor('showroom', {
          header: 'Showroom',
          enableSorting: false,
          cell: ({ getValue }) => {
            const { value, exhibId, exhibEventId } = getValue();
            return (
              <Switch
                key={exhibId}
                defaultChecked={value}
                onChange={async (e) => {
                  return await exhibitorRepository.mergeUserExhibitor(
                    'showroom',
                    e,
                    exhibId,
                    eventId
                  );
                }}
              />
            );
          },
        })
      );
    }

    return baseColumns;
  }, []);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleActionChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const action = event.target.value as string;
    setSelectedAction(action);

    switch (action) {
      case 'addExhibitors':
        // navigate to add exhibitors page or handle the action
        break;
      case 'exportSimple':
        // Handle the export CSV logic
        break;
      case 'uploadCSV':
        // Trigger CSV upload functionality
        break;
      case 'extractComplete':
        // Handle the complete extraction logic
        break;
      case 'exportExhibitors':
        // Handle exporting exhibitors
        break;
      default:
        break;
    }
  };
  type StatusKey = 'TRANSMIS' | 'ENREGISTRE' | 'EN_COURSE';

  const status: Record<StatusKey, string> = {
    TRANSMIS: 'Transmis',
    ENREGISTRE: 'Enregistré',
    EN_COURSE: 'En cours',
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getStatus = (e: { status: StatusKey }) => {
    return status[e.status];
  };
  const data = useMemo(
    () =>
      response
        .filter((e) => {
          if (filterCollectif === 'individuel') {
            return e.exhibitor_stand_type === filterCollectif;
          }
          if (filterCollectif) {
            return e.collectif === filterCollectif;
          }
          return true;
        })
        .filter((e) => {
          const regex = new RegExp(filterName, 'gi');
          return filterName
            ? regex.test(e.exhibitor_raison_sociale ?? '')
            : true;
        })
        .map((e) => {
          let collectif = '';
          collectif = e.collectif
            ? codifCollectif?.find(
                (ee) => ee.topicCode === `collectif:${e.collectif}`
              )?.label ?? ''
            : collectif;
          return {
            remove: {
              userId: e.user_id,
              exhibId: e.exhibitor_id,
              exhibName: e.exhibitor_raison_sociale,
            },
            new: {
              isNew: isAfter(new Date(e.user_created_at), new Date(2023, 8, 1)),
            },
            status:
              e.status === 'TRANSMIS'
                ? 'Transmis'
                : e.status === 'ENREGISTERED'
                ? 'Enregistré'
                : 'En cours',
            active: {
              value: !!e.user_email_verified_at,
              userId: e.user_id,
              exhibId: e.exhibitor_id,
            },
            visibility: {
              value: !!e.exhibitor_visibility,
              exhibId: e.exhibitor_id,
            },
            locked: {
              value: !!e.locked,
              exhibId: e.exhibitor_id,
            },
            id: e.exhibitor_id,
            userEmail: e.user_email,
            number: `ID ${String(e.exhibitor_id).padStart(4, '0')}`,
            showroom: {
              value: !!e.exhibitor_showroom,
              exhibId: e.exhibitor_id,
              exhibEventId: e.exhibitor_event_id ?? 0,
            },
            raisonSocial: {
              value: e.exhibitor_raison_sociale,
              exhibId: e.exhibitor_id,
            },
            nomCommercial: e.exhibitor_nom_societe,
            collectif,
            year: { year: e.year, exhibId: e.exhibitor_id },
            numeroHall: {
              stand: e.exhibitor_stand,
              hall: e.exhibitor_hall,
              exhibId: e.exhibitor_id,
            },
            edit: {
              exhibitorId: e.exhibitor_id,
            },
          };
        }),
    [response, codifCollectif, filterCollectif, filterName]
  );

  const table = useReactTable({
    // data: data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    data,
    columns,
    state: {
      sorting,
      // pagination,
    },
    // pageCount: Math.ceil(data.length / pageSize) || 1,
    manualPagination: true,
    // onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
  });
  const location = useLocation();

  useEffect(() => {
    if (location.state?.reloadKey) {
      setReload((r) => r + 1);
    }
  }, [location.state?.reloadKey]);
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <T.Container>
          <M.Loading loading={loading} error={error} fullWiewport>
            {!pathname.includes('events') ? (
              <A.Title
                as="h1"
                fontSize="48"
                mb="30"
                fw="black"
                textAlign="center"
              >
                {t('event:list_of_exhibitors')}
              </A.Title>
            ) : (
              ''
            )}
            <T.Flex
              valign="center"
              bg="grey-10"
              p="30"
              pb="10"
              mb="40"
              gap={2}
              className={s.justifycenter}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <T.Flex valign="center" gap="2">
                    <div>
                      {eventId ? (
                        <Button
                          sx={{
                            height: '55px',
                          }}
                          variant="outlined"
                          onClick={() => {
                            navigate(
                              routes.home2.path +
                                `events/${eventId}/exhibitor-list`
                            );
                          }}
                        >
                          {t('event:add_exhibitors')}
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={s.inputfilter}>
                      <TextField
                        variant="outlined"
                        placeholder={t('event:find_an_exhibitors')}
                        onChange={(e) => {
                          // setPagination((state) => ({
                          //   ...state,
                          //   pageIndex: 0,
                          // }));
                          setFilterName(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ width: 200 }}
                    >
                      <InputLabel id="actions-select-label">
                        {t('exhibitors:action')}
                      </InputLabel>
                      <Select
                        labelId="actions-select-label"
                        value={selectedAction}
                        // @ts-expect-error
                        onChange={handleActionChange}
                        label={t('exhibitors:action')}
                      >
                        <MenuItem value="exportSimple">
                          <CSVLink
                            style={{ textDecoration: 'none' }}
                            filename={`export${
                              filterCollectif ? `-${filterCollectif}` : ''
                            }-${format(new Date(), 'dd-MM-yyyy-HH-mm-ss')}.csv`}
                            separator={';'}
                            enclosingCharacter={'"'}
                            data={[
                              [
                                'Status',
                                'ID',
                                'Contact pour ce dossier',
                                'Raison social',
                                'Nom commercial',
                                'Year',
                                'Stand',
                                'Hall',
                                'Participation',
                              ],
                              ...data.map((e) => [
                                e.status.replace(/"/g, '""'),
                                e.id,
                                e.userEmail,
                                e.raisonSocial.value,
                                e.nomCommercial?.replace(/"/g, '""'),
                                e.year.year,
                                e.numeroHall.stand,
                                e.numeroHall.hall,
                                e.collectif ? e.collectif : 'Individual',
                              ]),
                            ]}
                          >
                            {t('event:simple_extraction')}
                          </CSVLink>
                        </MenuItem>
                        {user?.role === 'MODERATOR' ? (
                          ''
                        ) : (
                          <MenuItem value="uploadCSV">
                            {csvLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <span
                                onClick={() => {
                                  const fileInput =
                                    document.createElement('input');
                                  fileInput.type = 'file';
                                  fileInput.accept = '.csv';
                                  fileInput.onchange = async ({
                                    target,
                                  }: any) => {
                                    setCsvLoading(true);
                                    setCsvSuccess(false);
                                    setCsvError('');
                                    if (!target.files) return;
                                    const file = target.files[0];
                                    const formdata = new FormData();
                                    formdata.append('file', file);
                                    try {
                                      await exhibitorRepository.uploadCSV(
                                        formdata
                                      );
                                      setCsvSuccess(true);
                                    } catch (err: any) {
                                      setCsvError(`${err.message}`);
                                    } finally {
                                      setCsvLoading(false);
                                    }
                                  };
                                  fileInput.click();
                                }}
                              >
                                {t('event:upload_csv')}
                              </span>
                            )}
                          </MenuItem>
                        )}

                        <MenuItem value="exportExhibitors">
                          <>
                            <button
                              onClick={downloadCSV}
                              disabled={isLoading}
                              style={{
                                marginBottom: '10px',
                                display: !isDataReady ? 'block' : 'none',
                                backgroundColor: 'initial',
                                border: 'none',
                                fontSize: '16px',
                                margin: 'unset',
                                padding: 'unset',
                              }}
                            >
                              {isLoading
                                ? t('event:loading')
                                : t('event:prepare_csv')}
                            </button>
                            {isDataReady && (
                              <CSVLink
                                style={{ textDecoration: 'none' }}
                                filename={`export-exhibitors-${format(
                                  new Date(),
                                  'dd-MM-yyyy-HH-mm-ss'
                                )}.csv`}
                                separator=";"
                                data={csvData}
                              >
                                {t('event:export_exhibitors')}
                              </CSVLink>
                            )}
                          </>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </T.Flex>
                  <A.Text textAlign="center" mt="10" fontSize="14">
                    {t('event:number_exhibitors')}: {response.length}
                  </A.Text>
                </div>
              </div>
            </T.Flex>
            <T.Block mb="20">
              <table className={s.Table}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                            {{
                              asc: <A.Icon icon="chevron-down" ml="5" />,
                              desc: <A.Icon icon="chevron-up" ml="5" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </A.Button>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={s.ExhibitorList__row}
                        data-removed={row.getIsSelected() ? 'true' : undefined}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            <div className={s.ExhibitorList__row__div}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </T.Block>

            {/* <T.Flex gap="1" valign={'center'}>
              <A.Button
                appearance="small"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {'>'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </A.Button>
              <div>
                <span>Page </span>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of{' '}
                  {table.getPageCount()}
                </strong>
              </div>
            </T.Flex> */}
          </M.Loading>
        </T.Container>
      </div>
      {pathname.includes('exhibitor') && (
        <div className={s.ExhibitorList__outlet}>
          <div>
            <Link
              className={s.background}
              to={
                pathname.includes('events')
                  ? routes.home2.path + `events/${eventId ?? ''}`
                  : ''
              }
              onClick={() => setReload((e) => e + 1)}
            />
          </div>
          {!pathname.includes('product') && (
            <Link
              className={s.ExhibitorList__outlet__close}
              to={
                pathname.includes('events')
                  ? routes.admEventList.path + `/${eventId ?? ''}`
                  : ''
              }
              onClick={() => setReload((e) => e + 1)}
            >
              <div>
                <A.Icon icon="x-lg" />
              </div>
            </Link>
          )}
          <div className={s.ExhibitorList__outlet__content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}

export default ExhibitorList;
