export default {
  exhibitors: {
    list_of_exhibitors: 'List of companies',
    find_an_exhibitor: 'Find an exhibitor',
    import_exhibitor_database: 'Import exhibitor database',
    action: 'Action',
    simple_extraction: 'Simple extraction',
    active: 'Active',
    name_of_the_company: 'Name of the company',
    retirer: 'Remove',
    annuler: 'Cancel',
    confirm: 'Confirm',
    edit: 'Edit',
    numero_de_stand: 'Booth Number + Hall',
    participation: 'Participation',
    nom_commercial: 'Trade Name',
    my_company_is: 'My company is exhibiting in the French Pavilion',
    individual: 'On an individual or shared stand (with Business France)',
    sur_as_stand: 'On a regional or interprofessional collective stand',
    products_catalog: 'Products catalog',
  },
};
