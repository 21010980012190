export default {
  home: {
    title: 'Enregistrement des données',
    deadline: "Date limite d'enregistrement :",
    form: {
      success: 'Form submitted successfully !',
      folderContact: {
        title: 'Contact pour ce dossier',
        subtitle: 'Information non visible sur les supports de communication',
        firstname: 'Prénom',
        lastname: 'Nom',
        phone: 'Téléphone du contact',
        mobile: 'Portable du contact',
        email: 'Email du contact / login',
        password: 'Password',
      },
      companyInfo: {
        title: 'Informations sur votre entreprise',
        company: 'Raison sociale',
        commercialName: 'Nom Commercial',
        commercialNameInfo:
          'Tel que vous voulez le voir apparaitre sur les différentes communications',
        address: 'Adresse postale',
        zipcode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
        phone: 'Téléphone général',
        email: 'E-mail général de la société',
        website: 'Site Web',
        companyType: "Type d'entreprise",
        siret: 'Siret',
        domainSize: 'Taille du domaine',
        domainSizeDetail: '(en ha entre 0 et 100 000)',
        yearProduct: 'Production annuelle',
        yearProductDetail: '(en nombre de bouteilles entre 0 et 10 000 000)',
        exportPercent: 'Pourcentage d’export',
        exportMarket: 'Vos marchés à l’export (plusieurs choix possibles)',
        exportMarketWish:
          'Souhaits de développement à l’export ? (plusieurs choix possibles)',
        compagnyPresentation:
          'Présentation de la société en anglais (500 caractères maximum)',
        webLogo: 'Logo web',
        webLogoFormat: '(jpg, png, 5Mo maximum)',
        HdLogo: 'Logo HD',
        HdLogoFormat: '(pdf,eps,ai : 10Mo maximum)',
      },
      galery: {
        title: 'Galerie',
        photos: 'Photos de l’entreprise',
        photosDetail: '(5 items, jpg, png, 5Mo maximum)',
        videoLink: 'Lien vidéo Youtube de l’entreprise',
      },
      contact: {
        title: 'Contact',
        titleDetail: '(Information visible sur les supports de communication)',
        firstname: 'Prénom',
        lastname: 'Nom',
        mobile: 'Téléphone portable',
        phone: 'Téléphone',
        email: 'Email',
      },
      products: {
        title: 'Votre sélection de produits',
        info: 'Possibilité de présenter jusqu’à 15 références',
        addProduct: 'Ajouter un produit',
        product: 'Produit',
        edit: 'Édit.',
        checkbox:
          "Les informations à caractère personnel recueillies font l’objet d’un traitement par l'organisateur de l'évènement. Elles sont nécessaires à l’organisation de l’évènement ou pour vous adresser tout document et/ou service. L'organisateur pourra utiliser ces données afin de vous proposer des produits et/ou services utiles au développement de votre activité. Pour l’exercice de vos droits ou si vous ne souhaitez pas obtenir de propositions de l'organisateur concernant ses actions, prestations, évènements, nous vous invitons à lui faire parvenir un e-mail. Pour en savoir plus : Charte de protection des données personnelles.",
      },
      buttons: {
        save: 'Enregister',
        locked: 'Verrouillé',
        submit: 'Enregister et transmettre',
      },
      asterix: {
        '*': 'Possibilité de vous reconnecter et séquencer la saisie des informations',
        '**': 'Votre saisie est finalisée, les éléments ne peuvent plus être modifiés.',
      },
      qrcode: {
        title: 'QRCode fiche entreprise',
        text: 'Consulter la fiche de votre entreprise en ligne',
        download: 'Télécharger le QRCode',
      },
    },
    formInformation: {
      required_answer: 'Réponse obligatoire',
      add_new_question: 'Ajouter une nouvelle question',
      answer: 'Réponse',
      save: 'Sauvegarder',
      are_you_sure: 'Êtes-vous sûr de vouloir supprimer cette question?',
      confirm: 'Confirmer',
      cancel: 'Annuler',
    },
    product: {
      catalog_of_your_product: 'Catalogue de vos produits',
      new_product: 'Nouveau produit',
      search: 'Rechercher',
      type_of_product: 'Type de produit',
      enter_name_of_the_vitange: 'Rentrer le nom du produit',
      select_vintage_of_the_vitange:
        'Selectionner le millésime de cette produit',
      technical_sheet: 'Fichier PDF, 5mo max',
      edit: 'Editer',
      create_new_product: 'Créer un nouveau produit',
      change_vintage: 'Changer de produit',
      product_information: 'Informations produit',
      producer_name: 'Nom du producteur',
      commercial_name_of_the_vintage: 'Nom commercial du produit',
      description_of_the_product:
        'Description du produit en français (500 caractères maximum)',
      product_description_in_english:
        'Description du produit en anglais (500 caractères maximum)',
      logo_on_label: "Logo régions sur l'étiquette",
      pics_of_product: 'Visuel du produit',
      cuvee_information: 'Informations produit',
      product_type: 'Type de produit',
      type_of_wines: 'Type de vins',
      color: 'Couleur',
      region: 'Région',
      classification: 'Classification',
      type_of_culture: 'Type de culture',
      grape_variety: 'Cépage',
      perntage_grape_variety: '% Cépage',
      percentage_alcohol: '% Alcool',
      type_of_harvest: 'Type de vendage',
      allergen: 'Allergène(s)',
      flavors: 'Arômes',
      format: 'Format',
      price_range: 'Fourchette de prix',
      no_file_selected: 'Aucun fichier selectionné',
      choose_a_file: 'Choisir un fichier',
      tasting_note: 'Note de dégustation',
      edit_technical_sheet: 'Editer fiche technique',
      type_of_grape: 'Type de vendage',
    },
  },
};
