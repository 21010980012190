import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomLink } from 'components/CustomLink';
import useApiContext from 'hooks/useApiContext';
import routes from 'router/routes';
import { A, T } from 'TOMA';
import s from './Header.module.scss';
import { eRoles } from 'services/User/User.repository';
import { useTranslation } from 'react-i18next';
import useRepo from '../../hooks/useRepo';
import { iCompany } from '../../services/Company/Company.repository';
import { Helmet } from 'react-helmet';

function Header(): JSX.Element {
  const navigate = useNavigate();
  const { userRepository, companyRepository } = useApiContext();
  const [company, setCompany] = useState<iCompany>();
  const { response, loading } = useRepo(
    // eslint-disable-next-line
    async () => await companyRepository.getCompanyRest(),
    []
  );
  useEffect(() => {
    if (!loading) {
      setCompany(response);
      console.log(response, 'company');
    }
  }, [loading, response]);
  const user = userRepository.getUser();
  const { t } = useTranslation();
  const { i18n } = useTranslation(); // Initialize translation hooks
  const [language, setLanguage] = useState(i18n.language); // Get the current language
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  // Function to change language
  const handleLanguageChange = (event: any): void => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage); // Change the language
    localStorage.setItem('appLanguage', selectedLanguage);
    window.location.reload();
  };
  const { pathname } = useLocation();
  const formatDate = (dateString: any): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}/${month}/${year}`;
  };
  return (
    <>
      <T.Block
        bg="white"
        style={{ boxShadow: '0 10px 10px rgba(0,0,0,0.05)' }}
        className={s.Header}
      >
        <T.Container py="20">
          <T.Flex gap="2" halign="space-between" valign="center">
            <T.Block>
              <T.Flex valign={'center'}>
                <A.Image
                  className="hidden"
                  src={company?.logo_path ?? ''}
                  height="70"
                  width="auto"
                />
              </T.Flex>
            </T.Block>
            {user?.role &&
              ![eRoles.ADMIN, eRoles.MODERATOR, eRoles.SUPER_ADMIN].includes(
                user?.role
              ) && (
                <T.Col flex="0 0" className={s.Header__desk}>
                  <CustomLink
                    to={routes.home.path.replace(/\/$/, '')}
                    color="black"
                    textDecoration="none"
                    className={pathname === routes.home.path ? s.Active : ''}
                  >
                    {t('header:societe')}
                  </CustomLink>{' '}
                </T.Col>
              )}
            {user?.role && [eRoles.SUPER_ADMIN].includes(user?.role) && (
              <T.Col flex="0 0" className={s.Header__desk}>
                <CustomLink
                  to={routes.users.path}
                  color="black"
                  textDecoration="none"
                  className={pathname === routes.users.path ? s.Active : ''}
                >
                  {t('header:users')}
                </CustomLink>{' '}
              </T.Col>
            )}
            {user?.role && [eRoles.ADMIN].includes(user?.role) && (
              <T.Col flex="0 0" className={s.Header__desk}>
                <CustomLink
                  to={routes.profile.path}
                  color="black"
                  textDecoration="none"
                  className={pathname.includes('users') ? s.Active : ''}
                >
                  {t('header:users')}
                </CustomLink>{' '}
              </T.Col>
            )}
            {user?.role &&
              [eRoles.ADMIN, eRoles.MODERATOR].includes(user?.role) && (
                <T.Col flex="0 0" className={s.Header__desk}>
                  <CustomLink
                    to={routes.admEventList.path}
                    color="black"
                    textDecoration="none"
                    className={pathname.includes('events') ? s.Active : ''}
                  >
                    {t('header:events')}
                  </CustomLink>{' '}
                </T.Col>
              )}
            {user?.role === eRoles.EXHIBITOR && (
              <>
                <T.Col flex="0 0" className={s.Header__desk}>
                  <CustomLink
                    to={routes.eventlist.path}
                    color="black"
                    textDecoration="none"
                    className={pathname.includes('eventlist') ? s.Active : ''}
                  >
                    {t('header:events')}
                  </CustomLink>{' '}
                </T.Col>
                <T.Col flex="0 0" className={s.Header__desk}>
                  <CustomLink
                    to={routes.products.path}
                    color="black"
                    textDecoration="none"
                    className={pathname.includes('product') ? s.Active : ''}
                  >
                    {t('header:products')}
                  </CustomLink>{' '}
                </T.Col>
              </>
            )}
            {user?.role &&
              [eRoles.ADMIN, eRoles.MODERATOR].includes(user?.role) && (
                <T.Col flex="0 0" className={s.Header__desk}>
                  <CustomLink
                    to={routes.home.path.replace(/\/$/, '')}
                    color="black"
                    textDecoration="none"
                    className={pathname.includes('exhibitor') ? s.Active : ''}
                  >
                    {t('header:exhibitor')}
                  </CustomLink>{' '}
                </T.Col>
              )}
            <T.Col flex="40 0" className={s.Header__desk}></T.Col>
            {user?.role && (
              <T.Flex gap="2" valign={'center'}>
                <T.Col flex="20 0" className={s.Header__desk}>
                  <select
                    value={language}
                    onChange={handleLanguageChange}
                    style={{
                      padding: '5px',
                      borderRadius: '4px',
                      borderColor: '#ccc',
                    }}
                  >
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                  </select>
                </T.Col>
              </T.Flex>
            )}
            <T.Flex gap="2" valign={'center'}>
              <A.Image
                className="hidden"
                src={company?.right_logo_path ?? ''}
                height="50"
                width="auto"
              />
              {user?.role && [eRoles.ADMIN].includes(user?.role) && (
                <>
                  <T.Col flex="40 0" className={s.Header__desk}>
                    <CustomLink
                      to={routes.company.path}
                      color="black"
                      textDecoration="none"
                      className={
                        pathname === routes.company.path ? s.Active : ''
                      }
                    >
                      {t('header:company_settings')}
                    </CustomLink>{' '}
                  </T.Col>
                </>
              )}
              {user ? (
                <A.Button
                  appearance="link"
                  onClick={() => {
                    userRepository.logout().finally(() => {
                      navigate(routes.login.to(), { replace: true });
                    });
                  }}
                  color="black"
                  textDecoration="none"
                >
                  <div className={s.Header__desk}>{t('header:sign_out')}</div>
                  <div className={s.Header__mob}>
                    <A.Icon icon="box-arrow-right" fontSize="22" />
                  </div>
                </A.Button>
              ) : (
                <>
                  <T.Col flex="20 0" className={s.Header__desk}>
                    <select
                      value={language}
                      onChange={handleLanguageChange}
                      style={{
                        padding: '5px',
                        borderRadius: '4px',
                        borderColor: '#ccc',
                      }}
                    >
                      <option value="en">English</option>
                      <option value="fr">Français</option>
                    </select>
                  </T.Col>
                  <CustomLink
                    to={`${routes.login.path}${code ? `?code=${code}` : ''}`}
                    color="black"
                    textDecoration="none"
                  >
                    {t('login:signIn')}
                  </CustomLink>
                  <CustomLink
                    to={`${routes.signup.path}${code ? `?code=${code}` : ''}`}
                    color="black"
                    textDecoration="none"
                  >
                    {t('login:signUp')}
                  </CustomLink>
                </>
              )}
            </T.Flex>
          </T.Flex>
        </T.Container>
      </T.Block>
      <div style={{ backgroundColor: company?.color }}>
        <T.Block>
          <T.Container>
            <T.Flex halign="space-between" py="40" gap={4}>
              <T.Col>
                {user?.role !== eRoles.ADMIN && (
                  <>
                    {company?.event?.date_of_the_end && company?.event?.date ? (
                      <>
                        <T.Block my="10">
                          <A.Image
                            className="hidden"
                            src={company?.main_logo_path ?? ''}
                            height="40"
                            width="auto"
                          />
                        </T.Block>
                        <A.Text color="white" mb="10" fontSize="24" fw="bold">
                          {company?.event?.name}
                        </A.Text>
                        <A.Text color="white" mb="10" fontSize="18">
                          {company?.event?.subtitle}
                        </A.Text>
                        <div className={s.Header__line}></div>

                        <T.Block mt="15">
                          <A.Text color="white" fontSize="18">
                            <T.Flex className="flex" gap={1}>
                              <div>
                                <A.Icon
                                  icon="calendar2-week-fill"
                                  color="white"
                                />
                              </div>
                              <div>
                                Du {formatDate(company?.event?.date)} au{' '}
                                {formatDate(company?.event?.date_of_the_end)}
                              </div>
                              <div>
                                <A.Icon icon="geo-fill" color="white" />
                              </div>
                              <div>
                                {company?.event?.city},{' '}
                                {company?.event?.country}
                              </div>
                            </T.Flex>
                          </A.Text>
                        </T.Block>
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </T.Col>
            </T.Flex>
          </T.Container>
        </T.Block>
      </div>
    </>
  );
}

export default Header;
